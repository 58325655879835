let progressCallback;

export function upload(envelope, files, answer, expectedAnswer, chunkSize) {
  const queue = files.slice(0);
  queue.unshift(envelope);
  const queueLen = queue.length;

  return new Promise((resolve, reject) => {
    const protocol = location.protocol === "http:" ? "ws:" : "wss:";
    const socket = new WebSocket(`${protocol}//${location.host}/`);

    function uploadLoop(event) {
      if (event.data !== "ok" && event.data !== "ready") {
        socket.close();
        if (event.data === "failquestion") return reject(new Error("failquestion"));
        return reject(new Error(`Unexpected data received: ${String(event.data)}`));
      }
      const value = queue[0];
      if (value) {
        if (value.length === 0) {
          queue.shift();
          socket.send(0);
          if (progressCallback) {
            progressCallback(queueLen - queue.length, queueLen);
          }
        } else {
          const chunk = value.subarray(0, chunkSize);
          socket.send(chunk);
          queue[0] = value.subarray(chunkSize);
        }
      } else {
        socket.close();
        resolve();
      }
    }

    function onServerReady(event) {
      socket.onmessage = uploadLoop;
      if (answer !== "noCaptcha") {
        socket.send(JSON.stringify({ answer, expectedAnswer }));
      } else {
        uploadLoop(event);
      }
    }

    socket.onmessage = onServerReady;
    socket.onclose = function () {
      reject(new Error("Unexpected close"));
    };
    socket.onerror = function (event) {
      return reject(event);
    };
  });
}

export function setProgressCallback(callback) {
  progressCallback = callback;
}
