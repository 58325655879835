import encryptLeak from "./encryption.js";
import { setProgressCallback, upload } from "./upload.js";

const progress = document.getElementById("progress");
const uploadSuccess = document.getElementById("upload-success");
const uploadSuccessWithContactKey = document.getElementById(
  "upload-success-with-contact-key"
);
const upgradeNotice = document.getElementById("upgrade-notice");
const uploadError = document.getElementById("upload-error");
const questionError = document.getElementById("question-error");
const maxPayload = document.getElementById("maxpayload").value;
const maxMessageSize = document.getElementById("maxmessage").value;
const pubkey = document.getElementById("pubkey").value;
const expectedAnswer = document.getElementById("expectedanswer").value;
const enableAnswers = document.getElementById("enableAnswers").value === "true";
const fileList = document.getElementById("file-list");
const fileInputLabel = document.getElementsByClassName("fileInputLabel")[0];

let fileCount = 0;

function main() {
  const form = document.querySelector("form");
  if (!window.FileReader || !window.WebSocket) {
    upgradeNotice.className = "visible";
    const elementsToDisable = form.querySelectorAll("input, textarea, button");
    for (const element of elementsToDisable) {
      element.disabled = true;
    }
    return;
  }

  uploadError.addEventListener("click", handleUploadErrorClick);

  form.addEventListener("submit", handleFormSubmit, false);

  const fileInput = document.getElementById("file");
  fileInput.addEventListener("change", handleFilesChange);
}

function fileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(encryptLeak(pubkey, new Uint8Array(reader.result), true));
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
}

function handleFilesChange() {
  if (!this.files.length) return false;

  if (maxPayload && this.files[0].size > parseInt(maxPayload)) {
    // eslint-disable-next-line no-alert
    alert(
      `Filen du försöker att ladda upp är för stor. Den får vara max ${
        maxPayload / 1000000
      } megabyte.`
    );
    this.value = "";
    return;
  }

  let fileName = "";
  if (this.value) {
    fileName = this.value.split("\\").pop();
  }

  if (++fileCount === 1) {
    fileInputLabel.firstChild.textContent = "Lägg till ytterligare fil";
  }

  const listElement = document.createElement("li");

  const replacementInput = this.cloneNode();
  replacementInput.value = "";
  replacementInput.addEventListener("change", handleFilesChange);

  this.parentElement.replaceChild(replacementInput, this);

  const checkmark = document.createElement("img");
  checkmark.src = "img/attachment.svg";
  listElement.appendChild(checkmark);

  const textElement = document.createElement("span");
  textElement.textContent = fileName;
  listElement.appendChild(textElement);

  const newId = `file${fileCount}`;
  this.id = newId;
  this.name = newId;
  this.removeEventListener("change", handleFilesChange);
  listElement.appendChild(this);

  const deleteButton = document.createElement("img");
  deleteButton.src = "img/delete.svg";
  deleteButton.className = "deleteButton";
  deleteButton.addEventListener("click", deleteFile);
  listElement.appendChild(deleteButton);

  fileList.appendChild(listElement);
}

function handleUploadErrorClick() {
  uploadError.className = ".notice";
}

function handleFormSubmit(event) {
  event.preventDefault();

  const description = this.querySelector("#description").value;
  const name = this.querySelector("#name").value;
  const phone = this.querySelector("#phone").value;
  const email = this.querySelector("#email").value;
  let answer = "noCaptcha";
  if (this.querySelector("#answer") !== null) {
    answer = this.querySelector("#answer").value;
  }

  const files = [ ...this.querySelectorAll("input[name^=file]") ].flatMap((input) => [ ...input.files ]);

  // if there is no contact info include a two-part key that the submitter can use to identify themselves
  const useRandomKey = enableAnswers && !validateContactInfo(phone, email);

  if (files.length === 0 && description.length === 0) {
    // eslint-disable-next-line no-alert
    alert("Ange antingen en beskrivning, eller bifoga minst en fil");
    return;
  }

  const envelope = {
    encryptedOnClient: true,
    description,
    name,
    phone,
    email,
    contactInfoKey: useRandomKey ? randomKey() : null,
    contactInfoFileName: useRandomKey ? randomKey() : null,
    filenames: files.map((f) => f.name),
  };

  progress.style.display = "block";

  setProgressCallback(handleUploadProgress);

  encryptLeak(pubkey, JSON.stringify(envelope), false)
    .then((envelopeData) => {
      return Promise.all(files.map(fileToBuffer))
        .then((filesData) => {
          return upload(
            envelopeData,
            filesData,
            answer,
            expectedAnswer,
            parseInt(maxMessageSize)
          );
        })
        .then(() => {
          if (envelope.contactInfoKey !== null) {
            const contactKey = `${envelope.contactInfoKey}${envelope.contactInfoFileName}`;
            uploadSuccessWithContactKey.querySelector(".key").textContent = contactKey;
            uploadSuccessWithContactKey.className = "notice visible";
          } else {
            uploadSuccess.className = "notice visible";
          }
          document.body.className = "locked";
        });
    })
    .then(() => {
      setProgressCallback(null);
    })
    .catch((err) => {
      progress.removeAttribute("style");
      if (err.message === "failquestion") {
        questionError.querySelector(".qerror").textContent = "Du har angett fel text.";
      } else {
        uploadError.className = "notice visible";
      }
    });
}

function validateContactInfo(phone, email) {
  return !(phone.length === 0 && email.length === 0);
}

function randomKey(length = 15) {
  const alphabet = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNP23456789!#%&=?";
  return [ ...Array(length).keys() ].map(() => randChar(alphabet)).join("");
}

function randChar(alphabet) {
  const randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  const randomNumber = randomBuffer[0] / (0xffffffff + 1);
  const index = Math.floor(randomNumber * alphabet.length);
  return alphabet.charAt(index);
}

function handleUploadProgress(current, total) {
  const progressCount = parseInt((current / total) * 100, 10);
  progress.querySelector(".count").textContent = `${progressCount}%`;
  if (current === total) {
    progress.removeAttribute("style");
  }
}

function deleteFile(e) {
  const listElementToDelete = e.target.parentNode;
  listElementToDelete.remove();
  if (!fileList.children.length) {
    --fileCount;
    fileInputLabel.firstChild.textContent = "Välj fil";
  }
}

main();
