import { readKey, createMessage, encrypt } from "openpgp";

export default async function encryptLeak(pubkey, data, binary) {
  const publicKey = await readKey({ armoredKey: pubkey });
  const message = binary ? { binary: data } : { text: data };

  const options = {
    message: await createMessage(message),
    encryptionKeys: publicKey,
    format: "binary",
  };

  return await encrypt(options);
}
